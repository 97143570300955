.swiper-button-prev,
.swiper-button-next {
    height: 100%;
    background: #ffffffba;
    padding: 1.2rem;
    color: #000;
}

.swiper-button-next {
    top: 12px;
    right: 0;
}

.swiper-button-prev {
    top: 12px;
    left: 0;
}

.labelIcons {
    position: absolute;
    bottom: 10px;
    left: 10px;

    .icon {
        margin-bottom: 5px;

        .warranty {
            overflow: hidden;
            position: relative;

            .value {
                font-family: Raleway;
                position: absolute;
                font-size: 32px;
                color: #fff;
                font-weight: bold;
                line-height: 1.1;
                text-align: center;
                top: 0;
                left: 0;
                width: 100%;

                .afterValue {
                    font-size: 10px;
                    font-weight: bold;
                }
            }
        }
    }
}

.energyClassIcon {
    position: absolute;
    top: 20px;
    right: 0;
}
