.my_masonry_grid {
    display: flex;
    margin-left: -30px;
    width: auto;
}

.my_masonry_grid_column {
    padding-left: 30px;
    background-clip: padding-box;
}

.my_masonry_grid_column > div {
    margin-bottom: 30px;
}

.wrapperImage {
    width: fit-content;
    border-radius: 0.3rem;
    z-index: 0;

    .imageSection {
        z-index: 2;
        float: left;
        position: relative;
        margin-bottom: 15px;
        img {
            max-width: 100%;
            height: 100%;
        }

        .imageOverlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 0;
            transition: opacity 0.3s ease;
            pointer-events: none;

            &.overlayVisible {
                opacity: 1;
            }
        }

        .tagSection {
            position: absolute;
            flex-wrap: wrap;
            opacity: 0;
            top: 10px;
            width: 100%;
            transition: opacity 0.3s ease;

            &.tagsVisible {
                opacity: 1;
            }

            .tagsWrapper {
                margin-top: 0.1rem;
                width: fit-content;
                display: inline-flex;

                .tags {
                    background-color: var(--primary-color);
                    color: #fff;
                    padding: 0.4rem;
                    margin-right: 5px;
                }
            }
        }
    }

    .title {
        font-weight: 700;
        text-align: left;
        padding-top: 0.9rem;
        font-size: 1rem;
        font-family: var(--font-montserrat);
    }

    .description {
        font-weight: 400;
        color: #bfbfbf;
        font-size: 15px;
        font-family: var(--font-montserrat);
        padding-top: 0.9rem;
    }
}

.pageTitle {
    font-family: var(--font-raleway);
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 10px;
}

button.filterButton {
    position: fixed;
    right: calc(var(--bs-gutter-x) * 0.5);
    top: 100px;
    border: 0;
    z-index: 10;
    padding: 5px 15px;

    @media (max-width: 576px) {
        top: 70px;
    }

    @media (min-width: 768px) {
        display: none;
    }
}

.exhibitsFilters {
    font-family: var(--font-raleway);
    font-size: 14px;
    font-weight: 600;
    background-color: #e6e5e5;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    padding: 15px 0;
    position: relative;

    &.sticky {
        animation: slide-down 0.5s;
        top: -1px;
        z-index: 1111;
        position: sticky;

        @media (max-width: 768px) {
            top: 88px;
        }
        @media (max-width: 576px) {
            top: 58px;
        }
    }

    .filterText {
        display: inline-flex;
        margin: 5px;

        @media (max-width: 768px) {
            margin: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .filterClose {
            cursor: pointer;
            font-size: 2rem;

            @media (min-width: 768px) {
                display: none;
            }
        }
    }

    .item {
        font-size: 14px;
        font-weight: 600;
        display: inline-flex;
        margin: 5px;
        padding: 8px 22px;
        background-color: #fff;
        border-radius: 28px;
        border: 1px solid #ccc;

        @media (max-width: 768px) {
            margin: 10px;
        }

        &:hover,
        &:active {
            background-color: var(--primary-color);
            color: #fff;
            border: 0;
        }

        &.active {
            color: #fff;
            background-color: var(--primary-color);
        }
    }

    .resetButton {
        display: inline-block;
        cursor: pointer;
        margin-left: 5px;

        svg {
            color: #fff;
            height: 30px;
            width: 30px;
        }
    }

    .resetButtonGallery {
        display: inline-block;
        cursor: pointer;
        margin-left: 5px;
        margin-top: 5px;

        svg {
            color: #fff;
            height: 30px;
            width: 30px;
        }
    }
}

.zoomIcon {
    color: #fff;
    height: 40px;
    width: 40px;
}
